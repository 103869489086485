<script>
import PoolService from "@/services/app/pool.service";
import {notification} from "ant-design-vue";

export default {
  name: "EditPoolPage",
  data() {
    return {
      breadcrumb: [
        {
          path: '/edit-pool',
          label: 'Edit pool'
        }
      ],
      loading: false,
      form: this.$form.createForm(this, { minBetAmount: null, maxBetAmount: null }),
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
    this.getPoolDetail()
  },
  methods: {
    async getPoolDetail() {
      const response = await PoolService.getPool()
      if (response.code === 1 && response.data) {
        this.form.setFieldsValue({
          minBetAmount: response.data.minBetAmount,
          maxBetAmount: response.data.maxBetAmount,
        })
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true
          const response = await PoolService.editPool(values)
          if (response.code === 1) {
            notification.success({
              message: 'Edit pool successfully!'
            })
          } else {
            notification.success({
              message: response.message || 'Somethings went wrong!'
            })
          }
          this.loading = false
        }
      });
    },
  }
}
</script>

<template>
  <a-tabs class="transaction-tabs" default-active-key="1">
    <a-tab-pane key="1" tab="Time bet">
      <div class="transaction-content">
        Time bet
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="Time mining" :force-render="true">
      <div class="transaction-content">
        Time mining
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="Time payment" :force-render="true">
      <div class="transaction-content">
        Time payment
      </div>
    </a-tab-pane>
  </a-tabs>
<!--  <div class="edit-pool-page">-->
<!--    <a-row :gutter="[16,0]">-->
<!--      <a-col :xs="24" :sm="18" :md="12">-->
<!--        <a-form :form="form" @submit="handleSubmit">-->
<!--          <a-card>-->
<!--            <a-form-item label="Min bet amount" required>-->
<!--              <a-input-number placeholder="0" :min="0" style="width: 100%;" v-decorator="['minBetAmount', { rules: [{ required: true, message: 'Please input min amount!' }] }]" />-->
<!--            </a-form-item>-->
<!--            <a-form-item label="Max bet amount" required>-->
<!--              <a-input-number placeholder="0" :min="0" style="width: 100%;" v-decorator="['maxBetAmount', { rules: [{ required: true, message: 'Please input max amount!' }] }]" />-->
<!--            </a-form-item>-->
<!--          </a-card>-->
<!--          <div style="text-align: right">-->
<!--            <a-button htmlType="submit" type="primary" style="margin-top: 16px;" :loading="loading">Submit</a-button>-->
<!--          </div>-->
<!--        </a-form>-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--  </div>-->
</template>

<style scoped lang="scss">
.edit-pool-page {
  padding: 24px;
}
.transaction-content {
  padding: 0 16px;
}
</style>